import "../../pages/client/css/styles.css";

function Modal({ titulo, modalVisible, children }) {
  return (
    <div className={modalVisible ? "modal show" : "modal"}>
      <div className="modal-container">
        <h2>{titulo}</h2>
        {children}
      </div>
    </div>
  );
}

export default Modal;

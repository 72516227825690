import { useState, useEffect } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { auth, firestore } from "../../../firebase/firebase";
import Modal from "../../../components/modal/modal";
import useAlert from "../../../hooks/useAlert";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  orderBy,
  doc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";

const PRODUCTOS_COLLECTION = "productos";
const CATEGORIAS_COLLECTION = "categorías";

function Productos() {
  const [formValues, setFormValues] = useState({
    nombreProducto: "",
    photoURL: "",
    categoria: "",
    precio: "",
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("create");
  const [productos, setProductos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [showAlert, colorAlerta, alertMessage, showAlertMessage] = useAlert();

  const user = auth.currentUser;

  const productoExistente = productos.find(
    (producto) => producto.nombreProducto === formValues.nombreProducto
  );

  useEffect(() => {
    const obtenerProducto = () => {
      const queryRef = query(
        collection(firestore, PRODUCTOS_COLLECTION),
        orderBy("fechaCreacion", "desc") // Ordenar por fecha de creación en orden descendente (la más reciente primero)
      );
      onSnapshot(queryRef, (querySnapshot) => {
        try {
          const productosData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setProductos(productosData);
        } catch (error) {
          showAlertMessage(`Error al obtener las productoss: ${error}`, "rojo");
        }
      });
    };

    obtenerProducto();
  }, [showAlertMessage]);

  useEffect(() => {
    const obtenerCategoria = () => {
      const queryRef = query(
        collection(firestore, CATEGORIAS_COLLECTION),
        orderBy("fechaCreacion", "desc") // Ordenar por fecha de creación en orden descendente (la más reciente primero)
      );
      onSnapshot(queryRef, (querySnapshot) => {
        try {
          const categoriasData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCategorias(categoriasData);
        } catch (error) {
          showAlertMessage(`Error al obtener las categorías: ${error}`, "rojo");
        }
      });
    };

    obtenerCategoria();
  }, [showAlertMessage]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const toggleModal = (esEdicion) => {
    if (modalVisible) {
      setModalMode("create");
    } else {
      if (esEdicion) {
        setModalMode("edit");
      } else {
        setModalMode("create");
      }
    }
    setModalVisible(!modalVisible);
  };

  const crearProducto = async (e) => {
    e.preventDefault();

    const { nombreProducto, photoURL, categoria, precio } = formValues;

    if (!nombreProducto || !photoURL || !categoria || !precio) {
      showAlertMessage("Por favor, completa todos los campos.", "rojo");
      return;
    }

    if (productoExistente) {
      showAlertMessage("Este producto ya existe.", "rojo");
      return;
    }

    try {
      if (!user) {
        showAlertMessage("Inicia sesión para crear un producto.", "rojo");
        return;
      }

      const nuevaProducto = {
        ...formValues,
        creador: user.uid,
        user: {
          displayName: user.displayName,
          email: user.email,
          phoneNumber: user.phoneNumber,
        },
        fechaCreacion: serverTimestamp(),
      };

      await addDoc(collection(firestore, PRODUCTOS_COLLECTION), nuevaProducto);

      showAlertMessage("Producto creado con éxito", "verde");
      setFormValues({
        nombreProducto: "",
        photoURL: "",
        categoria: "",
        precio: "",
      });
    } catch (error) {
      showAlertMessage(`Error al crear el producto: ${error}`, "rojo");
      setFormValues({
        nombreProducto: "",
        photoURL: "",
        categoria: "",
        precio: "",
      });
    }
  };

  const actulaizarProducto = async (e) => {
    e.preventDefault();

    try {
      if (!user) {
        showAlertMessage("Inicia sesión para actualizar un producto.", "rojo");
        return;
      }

      if (!formValues.nombreProducto) {
        showAlertMessage("Ingrese un producto valido", "rojo");
        return;
      }

      console.log("as");
      await updateDoc(doc(firestore, PRODUCTOS_COLLECTION, productoExistente), {
        formValues,
        fechaCreacion: serverTimestamp(),
      });

      showAlertMessage("Producto actualizada con éxito", "verde");
      toggleModal();
    } catch (error) {
      showAlertMessage(`Error al actualizar la producto: ${error}`, "rojo");
    }
  };

  const borrarProducto = async (productoId) => {
    if (!user) {
      showAlertMessage("Inicia sesión para eliminar una producto.", "rojo");
      return;
    }

    if (!productoId) {
      showAlertMessage("ID de producto inválido.", "rojo");
      return;
    }

    try {
      await deleteDoc(doc(firestore, PRODUCTOS_COLLECTION, productoId));
      showAlertMessage("Producto eliminado con éxito.", "verde");
    } catch (error) {
      showAlertMessage(`Error al eliminar la producto: ${error}`, "rojo");
    }
  };

  return (
    <div className="mesas-container">
      <div className="crearmesas-container">
        <h2>Productos</h2>
        <button
          onClick={() => {
            toggleModal();
          }}
        >
          Crear Producto
        </button>
      </div>
      {productos.length === 0 ? (
        <table>
          <thead>
            <tr>
              <th scope="col">Imagen</th>
              <th scope="col">Producto</th>
              <th scope="col">Precio</th>
              <th scope="col">Categoria</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>No hay productos disponibles.</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <table>
          <thead>
            <tr>
              <th scope="col">Imagen</th>
              <th scope="col">Producto</th>
              <th scope="col">Precio</th>
              <th scope="col">Categoria</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {productos.map((producto) => (
              <tr key={producto.id}>
                <td>
                  <img
                    src={producto.photoURL}
                    className="categoria-img"
                    alt={`Imagen de ${producto.nombreProducto}`}
                  />
                </td>
                <td>{producto.nombreProducto}</td>
                <td>{producto.precio}</td>
                <td>{producto.categoria}</td>
                <td>
                  <button
                    className="editar"
                    onClick={() => {
                      toggleModal(true);
                    }}
                  >
                    <BiEdit />
                  </button>
                  <button
                    className="borrar"
                    onClick={() => borrarProducto(producto.id)}
                  >
                    <BiTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        titulo={modalMode === "edit" ? "Actualizar producto" : "Crear producto"}
        modalVisible={modalVisible}
      >
        <hr className="hr" />
        {modalMode === "edit" ? (
          <form onSubmit={actulaizarProducto}>
            <input
              type="text"
              name="nombreProducto"
              placeholder="Producto"
              value={formValues.nombreProducto}
              onChange={handleChange}
              className="crearmesa-input"
            />
            <input
              type="text"
              name="photoURL"
              placeholder="photoURL"
              value={formValues.photoURL}
              onChange={handleChange}
              className="crearmesa-input"
            />
            <select
              name="categoria"
              value={formValues.categoria}
              onChange={handleChange}
            >
              {categorias.map((categoria) => (
                <option key={categoria.id} value={categoria.nombreCategoria}>
                  {categoria.nombreCategoria}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="precio"
              placeholder="Precio"
              value={formValues.precio}
              onChange={handleChange}
              className="crearmesa-input"
            />
            <input
              type="submit"
              value="Actualizar"
              onClick={toggleModal}
              className="crearmesa-input crearmesa-submit"
            />
          </form>
        ) : (
          <form onSubmit={crearProducto}>
            <input
              type="text"
              name="nombreProducto"
              placeholder="Producto"
              value={formValues.nombreProducto}
              onChange={handleChange}
              className="crearmesa-input"
            />
            <input
              type="text"
              name="photoURL"
              placeholder="photoURL"
              value={formValues.photoURL}
              onChange={handleChange}
              className="crearmesa-input"
            />
            <select
              className="crearmesa-select"
              name="categoria"
              value={formValues.categoria}
              onChange={handleChange}
            >
              {categorias.map((categoria) => (
                <option key={categoria.id} value={categoria.nombreCategoria}>
                  {categoria.nombreCategoria}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="precio"
              placeholder="Precio"
              value={formValues.precio}
              onChange={handleChange}
              className="crearmesa-input"
            />
            <input
              type="submit"
              value="Crear"
              onClick={toggleModal}
              className="crearmesa-input crearmesa-submit"
            />
          </form>
        )}
      </Modal>
      {showAlert && (
        <div className={`alerta ${colorAlerta} mostrar`}>
          <p>{alertMessage}</p>
        </div>
      )}
    </div>
  );
}

export default Productos;

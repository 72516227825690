import { Link } from "react-router-dom";
import "../css/styles.css";

function Navbar({ toggleMenu, menuMobile }) {
  return (
    <nav>
      <div
        className={
          menuMobile
            ? " hamburguesa-btn hamburguesa-btn--show"
            : "hamburguesa-btn"
        }
        onClick={toggleMenu}
      >
        <span className="lineas"></span>
        <span className="lineas"></span>
        <span className="lineas"></span>
      </div>
      <ul>
        <li>
          <Link to="/admin/login">Log in</Link>
        </li>
        <li>
          <Link to="/admin/signup">Sign up</Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;

import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/firebase";
import { Navigate, Route, Routes } from "react-router-dom";
import Usuarios from "./pages/admin/usuarios/usuarios";
import Ajustes from "./pages/admin/ajustes/ajustes";
import Categorias from "./pages/admin/categorias/categorias";
import Productos from "./pages/admin/productos/productos";
import Login from "./pages/admin/login/login";
import Mesas from "./pages/admin/mesas/mesas";
import Navbar from "./components/navbar/navbar";
import Pedidos from "./pages/admin/pedidos/pedidos";
import Sidebar from "./components/sidebar/sidebar";
import Signup from "./pages/admin/signup/signup";
import Pagos from "./pages/admin/pagos/pagos";
import NotFoundPage from "./utils/NotFoundPage";

function Admin({
  username,
  setUsername,
  photoURL,
  setPhotoURL,
  tema,
  toggleTema,
}) {
  const [menuMobile, setMenuMobile] = useState(false);
  const [loading, setLoading] = useState(true);
  const toggleMenu = () => {
    if (window.innerWidth <= 768) setMenuMobile(!menuMobile);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUsername(user.displayName);
        setPhotoURL(user.photoURL);
      } else {
        setUsername(null);
        setPhotoURL(null);
      }
      setLoading(false);
    });

    return () => {
      setUsername(null);
      unsubscribe();
    };
  }, [setUsername, setPhotoURL]);

  return (
    <>
      <Sidebar
        menuMobile={menuMobile}
        toggleMenu={toggleMenu}
        username={username}
        photoURL={photoURL}
        loading={loading}
      />
      <section className="main-content">
        <Navbar toggleMenu={toggleMenu} menuMobile={menuMobile} />
        <div className="content">
          <Routes>
            <Route path="/" element={<Navigate to="/admin/pedidos" />} />
            <Route path="/pedidos" element={<Pedidos />} />
            <Route path="/mesas" element={<Mesas />} />
            <Route path="/pagos" element={<Pagos />} />
            <Route path="/categorias" element={<Categorias />} />
            <Route path="/productos" element={<Productos />} />
            <Route path="/usuarios" element={<Usuarios />} />
            <Route
              path="/ajustes"
              element={
                <Ajustes
                  setUsername={setUsername}
                  setPhotoURL={setPhotoURL}
                  tema={tema}
                  toggleTema={toggleTema}
                />
              }
            />
            <Route path="/login" element={<Login />} />
            <Route
              path="/signup"
              element={<Signup setUsername={setUsername} />}
            />
            <Route
              path="*"
              element={<NotFoundPage />}
            />
          </Routes>
        </div>
      </section>
    </>
  );
}

export default Admin;

import { useState, useEffect } from "react";

const useAlert = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [colorAlerta, setColorAlerta] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 1500);
    }
  }, [showAlert]);

  const showAlertMessage = (mensaje, color) => {
    setShowAlert(true);
    setAlertMessage(mensaje);
    setColorAlerta(color);
  };

  return [showAlert, colorAlerta, alertMessage, showAlertMessage];
};

export default useAlert;

import { useState, useEffect } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { auth, firestore } from "../../../firebase/firebase";
import Modal from "../../../components/modal/modal";
import useAlert from "../../../hooks/useAlert";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  orderBy,
  doc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";

const CATEGORIAS_COLLECTION = "categorías";

function Categorias() {
  const [formValues, setFormValues] = useState({
    nombreCategoria: "",
    photoURL: "",
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("create");
  const [categorias, setCategorias] = useState([]);
  const [showAlert, colorAlerta, alertMessage, showAlertMessage] = useAlert();

  const user = auth.currentUser;

  const categoriaExistente = categorias.find(
    (categoria) => categoria.nombreCategoria === formValues.nombreCategoria
  );

  useEffect(() => {
    const obtenerCategoria = () => {
      const queryRef = query(
        collection(firestore, CATEGORIAS_COLLECTION),
        orderBy("fechaCreacion", "desc") // Ordenar por fecha de creación en orden descendente (la más reciente primero)
      );
      onSnapshot(queryRef, (querySnapshot) => {
        try {
          const categoriasData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCategorias(categoriasData);
        } catch (error) {
          showAlertMessage(`Error al obtener las categorías: ${error}`, "rojo");
        }
      });
    };

    obtenerCategoria();
  }, [showAlertMessage]);

  const toggleModal = (esEdicion) => {
    if (modalVisible) {
      setModalMode("create");
    } else {
      if (esEdicion) {
        setModalMode("edit");
      } else {
        setModalMode("create");
      }
    }
    setModalVisible(!modalVisible);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const crearCategoria = async (e) => {
    e.preventDefault();

    if (!formValues.nombreCategoria || !formValues.photoURL) {
      showAlertMessage("Por favor, completa todos los campos.", "rojo");
      return;
    }

    if (categoriaExistente) {
      showAlertMessage("Esta categoría ya existe", "rojo");
      return;
    }

    try {
      if (!user) {
        showAlertMessage("Inicia sesión para crear una categoría.", "rojo");
        return;
      }

      const nuevaCategoria = {
        ...formValues,
        creador: user.uid,
        user: {
          displayName: user.displayName,
          email: user.email,
          phoneNumber: user.phoneNumber,
        },
        fechaCreacion: serverTimestamp(),
      };

      await addDoc(
        collection(firestore, CATEGORIAS_COLLECTION),
        nuevaCategoria
      );

      showAlertMessage("Categoría creada con éxito", "verde");
      setFormValues({ nombreCategoria: "", photoURL: "" });
    } catch (error) {
      showAlertMessage(`Error al crear la categoría: ${error}`, "rojo");
      setFormValues({ nombreCategoria: "", photoURL: "" });
    }
  };

  const actulaizarCategoria = async (e) => {
    e.preventDefault();

    try {
      if (!user) {
        showAlertMessage(
          "Inicia sesión para actualizar una categoría.",
          "rojo"
        );
        return;
      }

      if (!formValues.nombreCategoria) {
        showAlertMessage("Ingresa una categoría valida", "rojo");
        return;
      }

      console.log("as");
      // Lógica para actualizar la categoría en Firestore
      await updateDoc(
        doc(firestore, CATEGORIAS_COLLECTION, categoriaExistente),
        {
          formValues,
          fechaCreacion: serverTimestamp(),
        }
      );

      showAlertMessage("Categoría actualizada con éxito", "verde");
      toggleModal();
    } catch (error) {
      showAlertMessage(`Error al actualizar la categoría: ${error}`, "rojo");
    }
  };

  const borrarCategoria = async (categoriaId) => {
    if (!user) {
      showAlertMessage("Inicia sesión para eliminar una categoría.", "rojo");
      return;
    }

    if (!categoriaId) {
      showAlertMessage("ID de categoría inválido.", "rojo");
      return;
    }

    try {
      await deleteDoc(doc(firestore, CATEGORIAS_COLLECTION, categoriaId));
      showAlertMessage("Categoría eliminada con éxito.", "verde");
    } catch (error) {
      showAlertMessage(`Error al eliminar la categoría: ${error}`, "rojo");
    }
  };

  return (
    <div className="mesas-container">
      <div className="crearmesas-container">
        <h2>Categorías</h2>
        <button
          onClick={() => {
            toggleModal();
          }}
        >
          Crear categoría
        </button>
      </div>
      {categorias.length === 0 ? (
        <table>
          <thead>
            <tr>
              <th scope="col">Imagen</th>
              <th scope="col">Categorías</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>No hay categorías disponibles.</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <table>
          <thead>
            <tr>
              <th scope="col">Imagen</th>
              <th scope="col">Categorías</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {categorias.map((categoria) => (
              <tr key={categoria.id}>
                <td>
                  <img
                    src={categoria.photoURL}
                    className="categoria-img"
                    alt={`Imagen de ${categoria.nombreCategoria}`}
                  />
                </td>
                <td> {categoria.nombreCategoria}</td>
                <td>
                  <button
                    className="editar"
                    onClick={() => {
                      toggleModal(true);
                    }}
                  >
                    <BiEdit />
                  </button>
                  <button
                    className="borrar"
                    onClick={() => borrarCategoria(categoria.id)}
                  >
                    <BiTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        titulo={
          modalMode === "edit" ? "Actualizar Categoría" : "Crear Categoría"
        }
        modalVisible={modalVisible}
      >
        <hr className="hr" />
        {modalMode === "edit" ? (
          <form onSubmit={actulaizarCategoria}>
            <input
              type="text"
              name="nombreCategoria"
              placeholder="Categoría"
              value={formValues.nombreCategoria}
              onChange={handleChange}
              className="crearmesa-input"
            />
            <input
              type="text"
              name="photoURL"
              placeholder="PhotoURL"
              value={formValues.photoURL}
              onChange={handleChange}
              className="crearmesa-input"
            />
            <input
              type="submit"
              value="Actualizar"
              onClick={toggleModal}
              className="crearmesa-input crearmesa-submit"
            />
          </form>
        ) : (
          <form onSubmit={crearCategoria}>
            <input
              type="text"
              name="nombreCategoria"
              placeholder="Categoría"
              value={formValues.nombreCategoria}
              onChange={handleChange}
              className="crearmesa-input"
            />
            <input
              type="text"
              name="photoURL"
              placeholder="PhotoURL"
              value={formValues.photoURL}
              onChange={handleChange}
              className="crearmesa-input"
            />
            <input
              type="submit"
              value="Crear"
              onClick={toggleModal}
              className="crearmesa-input crearmesa-submit"
            />
          </form>
        )}
      </Modal>
      {showAlert && (
        <div className={`alerta ${colorAlerta} mostrar`}>
          <p>{alertMessage}</p>
        </div>
      )}
    </div>
  );
}

export default Categorias;

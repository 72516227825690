import "../css/styles.css";
import { useState, useEffect } from "react";
import { firestore } from "../../../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";
import useAlert from "../../../hooks/useAlert";
import { Link } from "react-router-dom";

function Inicio() {
  const [numeroMesa, setNumeroMesa] = useState("");
  const [mesas, setMesas] = useState([]);
  const [showAlert, colorAlerta, alertMessage, showAlertMessage] = useAlert();

  useEffect(() => {
    async function ObtenerMesas() {
      const querySnapshot = await getDocs(collection(firestore, "mesas"));
      const mesasData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
      }));
      const mesasNuevas = mesasData.map((mesa) => mesa.numeroMesa);
      setMesas(mesasNuevas.join(", "));
      console.log(mesasData);
    }
    ObtenerMesas();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const mesaArray = mesas.split(",");

    if (!numeroMesa || isNaN(numeroMesa)) {
      showAlertMessage("Ingresa un número de mesa válido.", "rojo");
    } else if (mesaArray.includes(numeroMesa)) {
      showAlertMessage("Mesa existente", "verde");
    } else {
      showAlertMessage("Error: Mesa no encontrada", "rojo");
    }
  };

  return (
    <div className="numeromesa-container">
      <div className="numeromesa-box">
        <h1>Esquiker</h1>
        <form className="numeromesa-form" onSubmit={handleSubmit}>
          <label>Introduzca tu numero de mesa {mesas}</label>
          <input
            type="number"
            placeholder="Ex: 3"
            className="numeromesa-input"
            value={numeroMesa}
            onChange={(e) => setNumeroMesa(e.target.value)}
          />
          <input type="submit" value="Entrar" className="numeromesa-input" />
        </form>
      </div>
      <Link to="/admin">Admin</Link>
      {showAlert && (
        <div className={`alerta ${colorAlerta} mostrar`}>
          <p>{alertMessage}</p>
        </div>
      )}
    </div>
  );
}

export default Inicio;

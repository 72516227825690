import "../css/styles.css";
import { useState } from "react";
import useAlert from "../../../hooks/useAlert";
import AuthButton from "../../../components/authButton";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../firebase/firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithPopup,
  signOut,
  updateProfile,
} from "firebase/auth";
import { FcGoogle } from "react-icons/fc";
import { BsGithub, BsEyeSlash, BsEye } from "react-icons/bs";

function Signup({ setUsername }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [showAlert, colorAlerta, alertMessage, showAlertMessage] = useAlert();
  const navigate = useNavigate();

  const manejarSignup = (event) => {
    event.preventDefault();

    if (!email || !password || !displayName) {
      showAlertMessage("Por favor, completa todos los campos", "gris");
      return;
    } else if (password.length < 6) {
      showAlertMessage(
        "Por favor, ingrese minimo 6 caracteres para la contraseña",
        "rojo"
      );
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        updateProfile(user, {
          displayName: displayName,
        })
          .then(() => {
            showAlertMessage(
              `Usuario creado exitosamente: ${user.displayName}`,
              "verde"
            );
            sendEmailVerification(auth.currentUser).then(() => {
              showAlertMessage(
                "Correo de verificacion enviado con exito",
                "verde"
              );
            });
            signOut(auth);
            setTimeout(() => {
              navigate("/admin/login");
            }, 1500);
          })
          .catch((error) => {
            showAlertMessage(
              `Error al actualizar el perfil del usuario: ${error}`,
              "rojo"
            );
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        showAlertMessage(
          `Error al crear una cuenta ${errorCode}. Por favor, inténtalo de nuevo ${errorMessage}`,
          "rojo"
        );
      });
  };

  const signUpWithGoogle = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user.displayName;
        showAlertMessage(`Iniciado con éxito con Google: ${user}`, "verde");
      })
      .catch((error) => {
        showAlertMessage(
          `Error al iniciar sesión con Google: ${error}`,
          "rojo"
        );
      });
  };

  const signUpWithGithub = () => {
    const provider = new GithubAuthProvider();

    signInWithPopup(auth, provider)
      .then((response) => {
        const user = response.user.displayName;
        showAlertMessage(`Iniciado con éxito con Github: ${user}`, "verde");
      })
      .catch((error) => {
        showAlertMessage(
          `Error al iniciar sesión con Github: ${error}`,
          "rojo"
        );
      });
  };

  return (
    <div className="signup-container">
      <h2>Sign up</h2>
      <form onSubmit={manejarSignup}>
        <label>Usuario</label>
        <input
          type="text"
          placeholder="Ex: EloiDev"
          maxLength={8}
          className="login-input"
          value={displayName}
          onChange={(event) => {
            setDisplayName(event.target.value);
          }}
          autoComplete="username"
        />
        <label>Email</label>
        <input
          type="email"
          placeholder="Ex: Example@gmail.com"
          className="login-input"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          autoComplete="email"
        />
        <label>Password</label>
        <div className="password-input-container">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Ex: 123456789"
            className="login-input"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            autoComplete="current-password"
          />
          <button
            type="button"
            className="eye-password-button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <BsEyeSlash className="eye-icon" />
            ) : (
              <BsEye className="eye-icon" />
            )}
          </button>
        </div>
        <button type="submit" className="boton-enviar">
          Sign Up
        </button>
      </form>
      <div className="linea-container">
        <hr className="linea" />
        <span>or</span>
        <hr className="linea" />
      </div>
      <div className="btn-login-container">
        <AuthButton
          providerIcon={<FcGoogle />}
          providerName="Google"
          onClick={signUpWithGoogle}
        />
        <AuthButton
          providerIcon={<BsGithub />}
          providerName="Github"
          onClick={signUpWithGithub}
        />
      </div>
      {showAlert && (
        <div className={`alerta ${colorAlerta}`}>
          <p>{alertMessage}</p>
        </div>
      )}
    </div>
  );
}

export default Signup;

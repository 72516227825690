import "../css/styles.css";
import { FiShoppingCart, FiUser } from "react-icons/fi";
import {
  MdOutlineTableRestaurant,
  MdRestaurant,
  MdOutlineAdminPanelSettings,
  MdSettings,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { RiBillLine, RiFolderOpenLine } from "react-icons/ri";

function Sidebar({ menuMobile, toggleMenu, username, photoURL, loading }) {
  return (
    <aside className={`sidebar ${menuMobile ? "sidebar--show" : ""}`.trim()}>
      <div className="usuario">
        {loading ? (
          <h1>Cargando...</h1>
        ) : (
          <div className="sidebar-user-data">
            {photoURL ? (
              <figure>
                <img src={photoURL} alt="" className="user-img" />
              </figure>
            ) : (
              <figure>
                <FiUser className="user-img" />
              </figure>
            )}
            <h1>{username ? username : "Sin Usuario"}</h1>
          </div>
        )}
      </div>
      <ul>
        <li>
          <Link to="/admin/pedidos" onClick={toggleMenu}>
            <MdRestaurant />
            <span className="sidebar-opcion">Pedidos</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/mesas" onClick={toggleMenu}>
            <MdOutlineTableRestaurant />
            <span className="sidebar-opcion">Mesas</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/pagos" onClick={toggleMenu}>
            <RiBillLine />
            <span className="sidebar-opcion">Pagos</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/categorias" onClick={toggleMenu}>
            <RiFolderOpenLine />
            <span className="sidebar-opcion">Categorías</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/productos" onClick={toggleMenu}>
            <FiShoppingCart />
            <span className="sidebar-opcion">Productos</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/usuarios" onClick={toggleMenu}>
            <MdOutlineAdminPanelSettings />
            <span className="sidebar-opcion">Usuarios</span>
          </Link>
        </li>
      </ul>
      <div className="ajustes-container">
        <Link className="ajustes" to="/admin/ajustes" onClick={toggleMenu}>
          <MdSettings />
          <span className="sidebar-opcion">Ajustes</span>
        </Link>
      </div>
    </aside>
  );
}

export default Sidebar;

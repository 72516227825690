import "./App.css";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Inicio from "./pages/client/Inicio/inicio";
import Admin from "./Admin";
import NotFoundPage from "./utils/NotFoundPage";

function App() {
  const [username, setUsername] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [tema, setTema] = useState("claro");

  useEffect(() => {
    // Obtener el estado del modo oscuro del localStorage
    const temaGuardado = localStorage.getItem("tema");

    // Si el modo oscuro estaba activado, actualizar el estado del componente
    if (temaGuardado) {
      setTema(temaGuardado);
    }
  }, []);

  const toggleTema = () => {
    const temaNuevo = tema === "claro" ? "oscuro" : "claro";
    setTema(temaNuevo);

    // Guardar el tema en el localStorage
    localStorage.setItem("tema", temaNuevo);
  };

  return (
    <main className={`App ${tema === "claro" ? "" : "oscuro"}`.trim()}>
      <Router>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route
            path="/admin/*"
            element={
              <Admin
                username={username}
                setUsername={setUsername}
                photoURL={photoURL}
                setPhotoURL={setPhotoURL}
                tema={tema}
                toggleTema={toggleTema}
              />
            }
          />
          <Route
            path="*"
            element={
              <div className="app-not-found-page-container">
                <NotFoundPage />
              </div>
            }
          />
        </Routes>
      </Router>
    </main>
  );
}

export default App;

import { Link } from 'react-router-dom';
import styles from './NotFoundPage.module.css';
import TresPuntos from '../components/tresPuntos';

const NotFoundPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <TresPuntos />
        <h2>EloiDev</h2>
      </div>
      <div className={styles.error}>
        <p>Página no encontrada</p>
        <p>Error: 404</p>
      </div>
      <Link to="/admin" className={styles.link}>
        Ir al inicio
      </Link>
    </div>
  );
};

export default NotFoundPage;
import "../css/styles.css";
import { useState } from "react";
import useAlert from "../../../hooks/useAlert";
import AuthButton from "../../../components/authButton";
import { GoogleAuthProvider, GithubAuthProvider } from "firebase/auth";
import { signInWithProvider } from "../../../firebase/authUtils";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase/firebase";
import { FcGoogle } from "react-icons/fc";
import { BsGithub, BsEyeSlash, BsEye } from "react-icons/bs";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, colorAlerta, alertMessage, showAlertMessage] = useAlert();
  const navigate = useNavigate();

  const manejarSignIn = (event) => {
    event.preventDefault();

    if (!email || !password) {
      showAlertMessage("Por favor, completa todos los campos", "gris");
      return;
    } else if (password.length < 6) {
      showAlertMessage(
        "Por favor, ingrese minimo 6 caracteres para la contraseña",
        "rojo"
      );
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        // Verificar si el correo electrónico está verificado
        if (user.emailVerified) {
          showAlertMessage(
            `Inicio de sesión exitoso: ${user.displayName}`,
            "verde"
          );
          setTimeout(() => {
            navigate("/admin");
          }, 1500);
        } else {
          showAlertMessage(
            "El correo electrónico aún no ha sido verificado. Por favor, verifica tu correo antes de iniciar sesión.",
            "rojo"
          );
          auth.signOut(); // Cerrar sesión para evitar el acceso no verificado.
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        showAlertMessage(
          `Error al iniciar sesión ${errorCode}. Por favor, inténtalo de nuevo ${errorMessage}`,
          "rojo"
        );
      });
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithProvider(provider)
      .then((response) => {
        const user = response.user.displayName;
        showAlertMessage(`Iniciado con éxito con Google: ${user}`, "verde");
      })
      .catch((error) => {
        showAlertMessage(
          `Error al iniciar sesión con Google: ${error}`,
          "rojo"
        );
      });
  };

  const signInWithGithub = () => {
    const provider = new GithubAuthProvider();

    signInWithProvider(provider)
      .then((response) => {
        const user = response.user.displayName;
        showAlertMessage(`Iniciado con éxito con Github: ${user}`, "verde");
      })
      .catch((error) => {
        showAlertMessage(
          `Error al iniciar sesión con Github: ${error}`,
          "rojo"
        );
      });
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={manejarSignIn}>
        <label>Email</label>
        <input
          type="email"
          placeholder="Ex: Example@gmail.com"
          className="login-input"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          autoComplete="email"
        />
        <label>Contraseña</label>
        <div className="password-input-container">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Ex: 123456789"
            className="login-input"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            autoComplete="current-password"
          />
          <button
            type="button"
            className="eye-password-button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <BsEyeSlash className="eye-icon" />
            ) : (
              <BsEye className="eye-icon" />
            )}
          </button>
        </div>
        <button type="submit" className="boton-enviar">
          Enviar
        </button>
      </form>
      <div className="linea-container">
        <hr className="linea" />
        <span>or</span>
        <hr className="linea" />
      </div>
      <div className="btn-login-container">
        <AuthButton
          providerIcon={<FcGoogle />}
          providerName="Google"
          onClick={signInWithGoogle}
        />
        <AuthButton
          providerIcon={<BsGithub />}
          providerName="Github"
          onClick={signInWithGithub}
        />
      </div>
      {showAlert && (
        <div className={`alerta ${colorAlerta}`}>
          <p>{alertMessage}</p>
        </div>
      )}
    </div>
  );
}

export default Login;

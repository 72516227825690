import { useState, useEffect } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import Modal from "../../../components/modal/modal";
import useAlert from "../../../hooks/useAlert";
import { auth, firestore } from "../../../firebase/firebase";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  doc,
  orderBy,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";

const MESAS_COLLECTION = "mesas";

function Mesas() {
  const [modalVisible, setModalVisible] = useState(false);
  const [mesas, setMesas] = useState([]);
  const [numeroMesa, setNumeroMesa] = useState("");
  const [showAlert, colorAlerta, alertMessage, showAlertMessage] = useAlert();
  const user = auth.currentUser;

  useEffect(() => {
    const obtenerMesas = () => {
      const queryRef = query(
        collection(firestore, MESAS_COLLECTION),
        orderBy("fechaCreacion", "desc") // Ordenar por fecha de creación en orden descendente (la más reciente primero)
      );
      onSnapshot(queryRef, (querySnapshot) => {
        try {
          const mesasData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMesas(mesasData);
        } catch (error) {
          showAlertMessage(`Error al obtener las mesas: ${error}`, "rojo");
        }
      });
    };

    obtenerMesas();
  }, [showAlertMessage]);

  const toogleModal = () => {
    setModalVisible(!modalVisible);
  };

  const crearMesa = async (e) => {
    e.preventDefault();

    const mesaExistente = mesas.find((mesa) => mesa.numeroMesa === numeroMesa);

    if (!numeroMesa || isNaN(numeroMesa)) {
      showAlertMessage("Ingresa un número de mesa válido.", "rojo");
    } else if (mesaExistente) {
      showAlertMessage("El numero de mesa ya existe.", "rojo");
    } else {
      try {
        if (!user) {
          showAlertMessage("Inicia sesión para crear una mesa.", "rojo");
          return;
        }

        const nuevaMesa = {
          numeroMesa: numeroMesa,
          creador: user.uid,
          user: {
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
          },
          fechaCreacion: serverTimestamp(),
        };

        await addDoc(collection(firestore, MESAS_COLLECTION), nuevaMesa);

        showAlertMessage("Mesa creada con éxito", "verde");
        setNumeroMesa("");
      } catch (error) {
        showAlertMessage(`Error al crear la mesa: ${error}`, "rojo");
      }
    }
  };

  const borrarMesa = async (idMesa) => {
    if (!user) {
      showAlertMessage("Inicia sesión para eliminar una mesa.", "rojo");
      return;
    }

    if (!idMesa) {
      showAlertMessage("ID de mesa inválido.", "rojo");
      return;
    }

    try {
      await deleteDoc(doc(firestore, MESAS_COLLECTION, idMesa));
      showAlertMessage("Mesa eliminada con éxito.", "verde");
    } catch (error) {
      showAlertMessage(`Error al eliminar la mesa: ${error}`, "rojo");
    }
  };

  const manejarNumeroMesa = (e) => {
    setNumeroMesa(e.target.value);
  };

  return (
    <div className="mesas-container">
      <div className="crearmesas-container">
        <h2>Mesas</h2>
        <button onClick={toogleModal}>Crear mesa</button>
      </div>
      {mesas.length === 0 ? (
        <table>
          <thead>
            <tr>
              <th>Número de Mesa</th>
              <th>Acciones</th>
            </tr>
          </thead>
        </table>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Número de Mesa</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {mesas.map((mesa) => (
              <tr key={mesa.id}>
                <td>{mesa.numeroMesa}</td>
                <td>
                  <button
                    className="editar"
                    onClick={() => borrarMesa(mesa.id)}
                  >
                    <BiEdit />
                  </button>
                  <button
                    className="borrar"
                    onClick={() => borrarMesa(mesa.id)}
                  >
                    <BiTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal titulo="Crear Mesa" modalVisible={modalVisible}>
        <hr className="hr"/>
        <form onSubmit={crearMesa}>
          <input
            type="number"
            placeholder="Numero de mesa"
            value={numeroMesa}
            onChange={manejarNumeroMesa}
            className="crearmesa-input"
          />
          <input
            type="submit"
            value="Crear"
            onClick={toogleModal}
            className="crearmesa-input crearmesa-submit"
          />
        </form>
      </Modal>
      {showAlert && (
        <div className={`alerta ${colorAlerta} mostrar`}>
          <p>{alertMessage}</p>
        </div>
      )}
    </div>
  );
}

export default Mesas;

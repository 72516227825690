import { useState } from "react";
import { FiUser, FiTrash, FiLogOut } from "react-icons/fi";
import {
  signOut,
  updateProfile,
  updateEmail,
  updatePassword,
  deleteUser,
} from "firebase/auth";
import Modal from "../../../components/modal/modal";
import useAlert from "../../../hooks/useAlert";
import { auth } from "../../../firebase/firebase";

function Ajustes({ setUsername, setPhotoURL, tema, toggleTema }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [newPhotoURL, setNewPhotoURL] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, colorAlerta, alertMessage, showAlertMessage] = useAlert();
  const user = auth.currentUser;

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (
      newPhotoURL === "" ||
      newUsername === "" ||
      email === "" ||
      password === ""
    ) {
      showAlertMessage("Por favor ingrese todos los valores", "gris");
    } else {
      try {
        // Actualiza el perfil
        await updateProfile(user, {
          displayName: newUsername,
          photoURL: newPhotoURL,
        });

        showAlertMessage("Perfil actualizado con éxito", "verde");
        setUsername(newUsername);
        setPhotoURL(newPhotoURL);

        // Actualiza el email
        await updateEmail(user, email);
        showAlertMessage("Email actualizado con éxito", "verde");

        // Actualiza la contraseña
        await updatePassword(user, password);
        showAlertMessage("Contraseña actualizada con éxito", "verde");
      } catch (error) {
        // Muestra si hay un error al actualizar el perfil
        showAlertMessage(
          `Error al actualizar el perfil: ${error.message}`,
          "rojo"
        );
      }
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        showAlertMessage("Sesion cerrada con exito", "verde");
      })
      .catch((error) => {
        showAlertMessage(`Error al cerrar sesion${error}`, "rojo");
      });
  };

  const handleDeleteUser = () => {
    deleteUser(user)
      .then(() => {
        showAlertMessage("Usuario eliminado correctamente", "verde");
      })
      .catch((error) => {
        showAlertMessage(`Error al eliminar el usuario ${error}`, "rojo");
      });
  };
  return (
    <div className={`settings-container ${tema === "oscuro" ? "oscuro" : ""}`}>
      <h1>Perfil</h1>
      <br />
      {user && (
        <div className="profile-container">
          <div className="profile-data">
            <button className="photo-button" onClick={toggleModal}>
              {user.photoURL ? (
                <figure>
                  <img
                    src={user.photoURL}
                    alt="Foto de Perfil"
                    className="user-img"
                  />
                </figure>
              ) : (
                <figure>
                  <FiUser className="user-img" />
                </figure>
              )}
            </button>
            <div className="settings-user-data">
              <h2>{user.displayName}</h2>
              <h3>{user.uid}</h3>
            </div>
          </div>
          <div className="settings-user-data">
            <h2>{user.email}</h2>
            <h2>
              {user.phoneNumber === null ? "Sin numero" : user.phoneNumber}
            </h2>
          </div>
          <div className="settings-user-data">
            <button onClick={handleSignOut} className="settings-button">
              Cerrar sesion
              <span>
                <FiLogOut className="react-icon" />
              </span>
            </button>
            <button onClick={handleDeleteUser} className="settings-button">
              Borrar cuenta
              <span>
                <FiTrash className="react-icon" />
              </span>
            </button>
          </div>
        </div>
      )}
      {showAlert && (
        <div className={`alerta ${colorAlerta}`}>
          <p>{alertMessage}</p>
        </div>
      )}
      <Modal titulo="Actualizar Perfil" modalVisible={modalVisible}>
        <form onSubmit={handleUpdateProfile}>
          <label>Usuario</label>
          <input
            type="text"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
          />
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="currentpassword"
          />
          <label>Photo URL</label>
          <input
            type="text"
            value={newPhotoURL}
            onChange={(e) => setNewPhotoURL(e.target.value)}
          />
          <input type="submit" value="Actualizar" onClick={toggleModal} />
        </form>
      </Modal>
      <button onClick={toggleTema}>
        {tema === "claro" ? "oscuro" : "claro"}
      </button>
    </div>
  );
}

export default Ajustes;

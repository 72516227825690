import "./css/styles.css";

const TresPuntos = () => {
  return (
    <div className="puntosContainer">
      <span className="punto"></span>
      <span className="punto"></span>
      <span className="punto"></span>
    </div>
  );
};

export default TresPuntos;